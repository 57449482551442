






















import {
  Component, Vue,
} from 'vue-property-decorator';

@Component({})
export default class HeaderListUsers extends Vue {
  private optionMenu = 0;

  setOption(option: number) {
    if (this.optionMenu !== option) {
      this.optionMenu = option;
      this.$emit('tab-active', option);
    }
  }
}
