























import { Component, Vue } from 'vue-property-decorator';

import { groupBy, orderBy } from 'lodash';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

import AchievementsList from '../AchievementsList/index.vue';

import StatsService from '@/services/Stats/StatsService';

import { Achievement } from '../../interface';

const TEXT_FEEDBACK_DEFAULT = 'Lista de usuário não carregadas';
const TEXT_FEEDBACK_NOT_ACHIEVEMENTS = 'Usuário não possui conquistas';

@Component({
  components: {
    ContainerFluid,
    BoxContainer,
    AchievementsList,
  },
})
export default class Profile extends Vue {
  private isLoadingAchievement = false;
  private isLoadingUserAchievement = false;
  private errorUserAchievements = false;

  private achievements: Achievement[] = [];
  private usersAchievements: Achievement[] = [];

  private FEEDBACK_DEFAULT = TEXT_FEEDBACK_DEFAULT;

  private StatsService = new StatsService();

  created() {
    this.getAchievements();
    this.getUserAchievements();
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get idStudent() {
    return this.profile?.idstudent;
  }

  get listAchievements() {
    const achievements = this.achievements.filter((achievement) => {
      const foundAchievement = this.usersAchievements.find((userAchievement) => achievement.ID === userAchievement.ID);

      return !foundAchievement;
    });

    const groupNameAchievement: Record<string, Achievement[]> = groupBy(achievements, 'groupName');

    const newAchievements = Object.keys(groupNameAchievement).reduce((acc: Achievement[], groupName: string) => {
      if (groupName === 'null') {
        return acc.concat(groupNameAchievement[groupName]);
      }

      const orderListAchievements: Achievement[] = orderBy(groupNameAchievement[groupName], 'order');

      return acc.concat(orderListAchievements[0]);
    }, [] as Achievement[]);

    return newAchievements;
  }

  get feedbackUsersAchievement() {
    return this.errorUserAchievements ? TEXT_FEEDBACK_DEFAULT : TEXT_FEEDBACK_NOT_ACHIEVEMENTS;
  }

  async getAchievements(): Promise<void> {
    try {
      this.isLoadingAchievement = true;

      const achievements: Achievement[] = await this.StatsService.getAchievements();

      this.achievements = achievements;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Error ao carregar as conquistas do usuário',
        status: 'error',
      });
    } finally {
      this.isLoadingAchievement = false;
    }
  }

  async getUserAchievements(): Promise<void> {
    try {
      this.isLoadingUserAchievement = true;

      const achievements: Achievement[] = await this.StatsService.getUserAchievements(Number(this.idStudent));

      this.usersAchievements = achievements;

      this.errorUserAchievements = false;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Error ao carregar as conquistas do usuário',
        status: 'error',
      });

      this.errorUserAchievements = true;
    } finally {
      this.isLoadingUserAchievement = false;
    }
  }
}
