



















import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import Select from '../Input/Select/Select.vue';

import {
  currentMonth, currentYear,
} from '@/share/Util/Dates/index';

import { LIST_MONTHS } from '@/constant/Dates';
import { HISTORICAL_BEGINNIG_YEAR } from './constants';

const CURRENT_MOTH = LIST_MONTHS[Number(currentMonth) - 1].value;

interface Data {
  title: string;
  value: string;
}

@Component({
  components: {
    Select,
  },
})
export default class DataFilters extends Vue {
  @Prop() month!: string;
  @Prop() year!: number;

  private selectedMonth = '';
  private selectedYear = Number(currentYear);

  get monthsAvailable() {
    const indexCurrentMonth = Number(currentMonth) - 1;
    let monthsAvailable = LIST_MONTHS;

    if (this.selectedYear === Number(currentYear)) {
      monthsAvailable = LIST_MONTHS.filter((month: Data, index: number) => index <= indexCurrentMonth);
    }

    return monthsAvailable;
  }

  get yearsAvailable() {
    const listYears = [];
    for (let i = HISTORICAL_BEGINNIG_YEAR; i <= Number(currentYear); i += 1) {
      listYears.push({
        title: i,
        value: i,
      });
    }

    return listYears;
  }

  @Watch('month', { immediate: true })
  @Watch('year')
  getDatesFromProp() {
    this.selectedMonth = this.month || CURRENT_MOTH;
    this.selectedYear = this.year || this.selectedYear;
  }

  emitSelectedMonth(month: string) {
    this.selectedMonth = month;
    this.$emit('selected-month', month);
  }

  emitSelectedYear(year: number) {
    this.selectedYear = year;
    this.$emit('selected-year', year);
  }
}
