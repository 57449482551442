




























































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    Loading,
  },
})
export default class ContentNotifications extends Vue {
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: true }) isNewNotification!: boolean;
  @Prop() feedbackWithoutNotifications!: string;
  @Prop() titleHeader!: string;
  @Prop() notifications!: Record<string, any>;

  @Watch('notifications', {
    deep: true,
  })
  setScrollListNotifications() {
    this.$nextTick(() => {
      const listRef: any = this.$refs;

      if (!listRef || !listRef.list || !listRef.list.scrollTop) return;

      listRef.list.scrollTop = 0;
    });
  }

  redirectToPage(link: string) {
    this.setTrackAmplitude('Botão - Ver');

    const LINK_PLATFORM = 'plataforma.explicae.com.br';
    const linkIsInternal = link.includes(LINK_PLATFORM);

    if (linkIsInternal) this.$router.push(link);
    else window.open(link, '_blank');
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Notification',
          local,
        },
      },
    });
  }
}
