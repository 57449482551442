export const DATA_CARD: Array<Record<string, any>> = [
  {
    id: 1,
    contextLocal: 'aulas',
    contextBack: 'video',
    title: 'Aulas',
    description: 'Visitadas em',
    textTooltip: 'Aulas assistidas',
    textTitleGraphic: 'Total de aulas visitadas no mês: ',
    progress: 50,
    topicsWatch: 300,
    openCard: false,
  },
  {
    id: 2,
    contextLocal: 'materiais',
    contextBack: 'materiais',
    title: 'Materiais',
    description: 'Visitadas em',
    textTooltip: 'Materiais acessados',
    textTitleGraphic: 'Total de materiais visitados no mês: ',
    progress: 30,
    topicsWatch: 20,
    openCard: false,
  },
  {
    id: 3,
    contextLocal: 'exercícios',
    contextBack: 'exercice',
    title: 'Exercícios',
    description: 'Visitadas em',
    textTooltip: 'Exercícios realizados',
    textTitleGraphic: 'Total de exercícios realizados no mês: ',
    progress: 70,
    topicsWatch: 10,
    openCard: false,
  },
  {
    id: 4,
    contextLocal: 'acesso',
    contextBack: 'access',
    title: 'Histórico de acesso',
    description: 'Visitadas em',
    totalView: 0,
    openCard: false,
    headerTable: [
      {
        title: 'Acessado em',
      },
    ],
  },
];

export const CONTEXTS = {
  VIDEOS: 'aulas',
  EXERCISES: 'exercícios',
  MATERIALS: 'materiais',
  ACCESS: 'acesso',
};
