














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import ModalPassword from './components/ModalPassword/index.vue';

import ProfileService from '@/services/Profile/ProfileService';
import AuthService from '@/services/Auth/AuthService';

import ActiveModal from '@/share/Util/ActiveModal';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { FEEDBACK_MODAL, FeedbackModal as IFeedbackModal } from './constants/FeedbackModal';

export interface DataChangePassword {
  currentPassword: string | null;
  newPassword: string | null;
  confirmedPassword: string | null;
}

@Component({
  components: {
    Loading,
    ModalPassword,
    FeedbackRequiredField,
  },
})
export default class ChangePassword extends Vue {
  private data: DataChangePassword = {
    currentPassword: null,
    newPassword: null,
    confirmedPassword: null,
  }

  private show = {
    currentPassword: false,
    newPassword: false,
    confirmedPassword: false,
  };

  private isErrorRequest = false;
  private isLoading = false;

  private ProfileService = new ProfileService();
  private AuthService = new AuthService();
  private setModal = new ActiveModal();

  get messageFeedback(): IFeedbackModal {
    if (!this.verifyPassword(this.data.newPassword || '')) return FEEDBACK_MODAL.InvalidPassword;

    if (this.isDifferentPasswordEntry) return FEEDBACK_MODAL.DifferentPassword;

    return FEEDBACK_MODAL.Default;
  }

  get isDifferentPasswordEntry() {
    if (this.data.confirmedPassword) return this.data.newPassword !== this.data.confirmedPassword;

    return false;
  }

  get currentPasswordFieldIsEmpty() {
    return !this.data.currentPassword && this.data.currentPassword === '';
  }

  get newPasswordFieldIsEmpty() {
    return !this.data.newPassword && this.data.newPassword === '';
  }

  get confirmedPasswordFieldIsEmpty() {
    return !this.data.confirmedPassword && this.data.confirmedPassword === '';
  }

  verifyPassword(password: string) {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=*!()_+\-={}[\]\\|:;"'<>,.?/`~])(?=.*\d).{8,30}$/;

    return regex.test(password);
  }

  checkedInput() {
    return (
      this.data.currentPassword && this.data.newPassword && this.data.confirmedPassword
    ) && (
      this.data.newPassword === this.data.confirmedPassword
    );
  }

  @Watch('data.newPassword', {
    deep: true,
  })
  clearConfirmedPassword() {
    if (this.data.newPassword === '') this.data.confirmedPassword = '';
  }

  async submitPassword() {
    try {
      this.isLoading = true;

      this.verifyRequest();

      const response = await this.ProfileService.updatePassword({
        oldPassword: this.data.currentPassword || '',
        newPassword: this.data.newPassword || '',
        confirmPassword: this.data.confirmedPassword || '',
      });

      if (response && response?.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.isErrorRequest = false;
        this.setModal.activeModal('modalProfilePassword');

        this.resetData();
      }
    } catch (error) {
      this.isErrorRequest = true;
      this.setModal.activeModal('modalProfilePassword');
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  verifyRequest() {
    if (
      !this.verifyPassword(this.data.newPassword || '')
      || this.isDifferentPasswordEntry
      || this.currentPasswordFieldIsEmpty
      || this.newPasswordFieldIsEmpty
      || this.confirmedPasswordFieldIsEmpty
    ) {
      this.isErrorRequest = true;
      throw new Error();
    }
  }

  resetData() {
    this.data.currentPassword = null;
    this.data.newPassword = null;
    this.data.confirmedPassword = null;
  }
}
