



























import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { FeedbackModal as IFeedbackModal } from '../../constants/FeedbackModal';

@Component({
  components: {
    Modal,
  },
})
export default class ModalPassword extends Vue {
  @Prop({ default: false }) isErrorRequest!: boolean;
  @Prop({ required: true }) messageFeedback!: IFeedbackModal;
}
