






































import { Component, Vue } from 'vue-property-decorator';

import CustomerService from '@/components/CustomerService/index.vue';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { PLAN_ID_LIFETIME } from './constants/PlanId';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

@Component({
  components: {
    CustomerService,
  },
})
export default class Plan extends Vue {
  get plan() {
    return {
      title: this.$store.getters.profile.plan.title,
      dateLimit: this.$store.getters.profile.plan.expireDate,
      quantityForum: 0,
      details: this.$store.getters.profile.plan.content,
    };
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get isPlanID() {
    return this.$store.getters.profile?.plan?.planID;
  }

  get description() {
    if (this.isPlanFree || PLAN_ID_LIFETIME.includes(this.isPlanID)) return 'Seu plano possui tempo ilimitado.';

    return this.plan.dateLimit
      ? `Seu plano irá expirar em ${this.formatDate(this.plan.dateLimit)} às ${this.formatTime(this.plan.dateLimit)}`
      : '';
  }

  get isShowRenewPlan() {
    return this.$store.getters.renewPayment;
  }

  formatDate(date: string) {
    const newDate = new Date(date);
    const day = setNumber(newDate.getUTCDate());
    const month = setNumber(newDate.getUTCMonth() + 1);
    const year = newDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }

  formatTime(date: string) {
    const time = new Date(date);

    return `${String(time.getUTCHours()).padStart(2, '0')}:${String(time.getUTCMinutes()).padStart(2, '0')}:${String(time.getUTCSeconds()).padStart(2, '0')}`;
  }

  redirectPageRenewPlan() {
    this.$router.push({
      name: 'RenewPlan',
    });
  }
}
