import AppClient from '../AppClient';

export default class InstitutionService {
  private basePath = process.env.VUE_APP_INSTITUTION_API;

  private Client = new AppClient({ url: this.basePath });

  async getClassData() {
    const URI = '/institutions/student/class';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getMyClass(page = 1, limit = 10) {
    const URI = '/institutions/classes/students/list';

    const response = await this.Client.get(URI, { page, limit });

    return response?.data;
  }

  async getMyTeachers(page = 1, limit = 10) {
    const URI = '/institutions/teachers/list';

    const response = await this.Client.get(URI, { page, limit });

    return response?.data;
  }
}
