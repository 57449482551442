
import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';

import { Bar } from 'vue-chartjs';

const DEFAULT_COLOR = '--primarycolor-dark';

@Component({
  extends: Bar,
})
export default class BarChart extends Mixins(Bar) {
  @Prop() dataLabel!: [];
  @Prop() dataGraphic!: [];
  @Prop({ default: '--primarycolor-dark' }) backgroundColor!: string;
  @Prop({ default: '' }) textTitle!: string;
  @Prop({ default: 'Conteúdo acessado' }) labelTooltip!: string;
  @Prop({ default: DEFAULT_COLOR }) color!: string;

  mounted() {
    this.initRenderChart();
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  @Watch('isDarkMode')
  initRenderChart() {
    this.renderChart({
      labels: this.dataLabel,
      datasets: [
        {
          type: 'bar',
          label: this.labelTooltip,
          data: this.dataGraphic,
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(this.color),
        },
      ],
    },
    {
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label(tooltipItems: any): string {
              return `${tooltipItems.formattedValue} conteúdo acessado`;
            },
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: this.textTitle,
        fontColor: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
        fontStyle: '500',
        position: 'bottom',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
          },
          gridLines: {
            display: true,
            color: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-medium'),
          },
        }],
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-pure'),
          },
          gridLines: {
            display: true,
            color: getComputedStyle(document.documentElement).getPropertyValue('--neutralcolor-low-medium'),
          },
        }],
      },
      responsive: true,
      maintainAspectRatio: false,
    });
  }
}
