const FEEDBACK_MODAL = {
  InvalidPassword: {
    message: 'Por favor digite uma senha válida.',
  },
  DifferentPassword: {
    message: 'Os campos de senha não conferem.',
  },
  Default: {
    message: 'Por favor, verifique se a senha atual está correta ou se é diferente da nova senha.',
  },
};

interface FeedbackModal {
  message: string;
}

export {
  FEEDBACK_MODAL,
  FeedbackModal,
};
