import { render, staticRenderFns } from "./ListUsersLoading.vue?vue&type=template&id=0671257c&scoped=true&"
import script from "./ListUsersLoading.vue?vue&type=script&lang=ts&"
export * from "./ListUsersLoading.vue?vue&type=script&lang=ts&"
import style0 from "./ListUsersLoading.scss?vue&type=style&index=0&id=0671257c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0671257c",
  null
  
)

export default component.exports