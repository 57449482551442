





















import { Component, Vue } from 'vue-property-decorator';

import ContentNotifications from './components/ContentNotifications/ContentNotifications.vue';

import NotificationsServices from '@/services/Notifications/NotificationsServices';

import { Notifications } from '@/globalInterfaces/Notifications';

@Component({
  components: {
    ContentNotifications,
  },
})
export default class Notification extends Vue {
  private recentNotifications: Notifications | null = null;
  private oldNotifications: Notifications | null = null;

  private isLoadingRecentNotifications = false;
  private isLoadingOldNotifications = false;

  private NotificationsServices = new NotificationsServices();

  async mounted() {
    await this.getRecentNotifications();
    await this.getNotificationsRead();
    this.updateNotifications();
    this.setShowNotification();
  }

  async getRecentNotifications() {
    try {
      this.isLoadingRecentNotifications = true;

      const notifications = await this.NotificationsServices.getNotificationsUnread();

      this.recentNotifications = this.reverseNotifications(notifications);

      this.updateNotifications();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as notificações recentes.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingRecentNotifications = false;
    }
  }

  async getNotificationsRead() {
    try {
      this.isLoadingOldNotifications = true;
      const notifications = await this.NotificationsServices.getNotificationsReaded();
      this.oldNotifications = this.reverseNotifications(notifications);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as notificações lidas.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingOldNotifications = false;
    }
  }

  reverseNotifications(notifications: Notifications) {
    return {
      ...notifications,
      notifications: notifications.notifications.reverse(),
    };
  }

  updateNotifications() {
    try {
      if (this.recentNotifications && this.recentNotifications.count) {
        this.NotificationsServices.updateNotifications();

        this.$store.commit('setQuantityNotifications', 0);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setShowNotification() {
    this.$store.commit('setIsShowNotification', true);
  }
}
