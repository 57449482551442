




















import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import Menu from './components/Menu/index.vue';
import Achievements from './components/Achievements/index.vue';
import MyClass from './components/MyClass/index.vue';
import MyPerformance from './components/MyPerformance/index.vue';
import MyData from './components/MyData/MyData.vue';
import Plan from './components/Plan/Plan.vue';
import ChangePassword from './components/ChangePassword/ChangePassword.vue';
import Notification from './components/Notification/Notification.vue';

import { DEFINITION_NAME_TEACHER_B2B, COMPONENTS_MENU, COMPONENTS_MENU_B2B } from './constants';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    Menu,
    Achievements,
    MyClass,
    MyPerformance,
    MyData,
    Plan,
    ChangePassword,
    Notification,
    Footer,
    ContainerFluid,
  },
})
export default class Profile extends Vue {
  private indexOptionMenuActive: number = this.$store.getters.tabProfile;

  async mounted() {
    this.setBreadCrumbs();
  }

  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get isTeacher() {
    return this.$store.getters.profile?.definitioName === DEFINITION_NAME_TEACHER_B2B;
  }

  get componentsMenu() {
    if (this.isB2b) {
      return this.isTeacher ? this.menuWithoutMyClass : COMPONENTS_MENU;
    }

    return this.menuWithoutMyClass;
  }

  get menuWithoutMyClass() {
    return COMPONENTS_MENU.filter((menu: string) => !COMPONENTS_MENU_B2B.includes(menu));
  }

  get currentMenuComponent() {
    return this.componentsMenu[this.indexOptionMenuActive];
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  @Watch('$store.getters.tabProfile')
  setIsActiveMenu() {
    this.indexOptionMenuActive = this.$store.getters.tabProfile;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Meu perfil', to: null },
    ]);
  }
}
