
































import { Component, Vue, Prop } from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import Achievement from '../Achievement/index.vue';
import Loading from './Loading.vue';

import { Achievement as IAchievement } from '../../interface';

@Component({
  components: {
    Achievement,
    Loading,
    FeedbackUser,
  },
})
export default class Profile extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) feedbackText!: string;
  @Prop({ required: true }) isLoading!: boolean;
  @Prop({ required: true }) achievements!: IAchievement[];
  @Prop({ default: false }) active!: boolean;
}

