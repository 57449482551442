











































import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalMyData extends Vue {
  @Prop({ default: false }) isErrorRequest!: boolean;

  goToDashboard() {
    this.$router.push({
      name: 'Dashboard',
    });
  }
}
