export const MENU_PROFILE = [
  'Meu desempenho',
  'Conquistas',
  'Minha turma',
  'Meus dados',
  'Meu plano',
  'Alterar senha',
  'Notificações',
];

export const MENU_B2B = [
  'Minha turma',
];

export const COMPONENTS_MENU = [
  'MyPerformance',
  'Achievements',
  'MyClass',
  'MyData',
  'Plan',
  'ChangePassword',
  'Notification',
];

export const COMPONENTS_MENU_B2B = [
  'MyClass',
];

export const DEFINITION_NAME_TEACHER_B2B = 'Professor B2B';
