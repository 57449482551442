



















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';

import Calendar from '@/assets/icons/Calendar.vue';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

@Component({
  components: {
    Calendar,
    TheMask,
    FeedbackRequiredField,
  },
})
export default class PersonalData extends Vue {
  @Prop() data!: Record<string, any>;
  @Prop({ default: false }) isUpdated!: boolean;

  private isActive = {
    ddd: false,
    phone: false,
    isStudent: false,
  }

  get nameFieldIsEmpty() {
    return !this.data.name;
  }

  get nicknameFieldIsEmpty() {
    return !this.data.nickname;
  }

  get birthdayFieldIsEmpty() {
    return !this.data.birthday;
  }

  get genderFieldIsEmpty() {
    return !this.data.gender && this.data.gender !== 0;
  }

  get IsActiveInput() {
    this.isActive.ddd = !!this.data.ddd && this.isUpdated;
    this.isActive.phone = !!this.data.phone && this.isUpdated;
    this.isActive.isStudent = !!this.data.isStudent && this.isUpdated;

    return this.isActive;
  }
}
